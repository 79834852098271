import React from 'react';

import classNames from 'classnames/bind';
import style from './Video.module.scss';
const cx = classNames.bind(style);

export type VideoProps = {
    className?: string;
    controls?: boolean;
    src: string;
};

export const Video: React.FC<VideoProps> = (props) => {
    const { className, controls, src } = props;

    return <video className={cx('video', className)} src={src} controls={controls} />;
};

export default Video;
