import { useInView } from 'react-intersection-observer';
import React, { CSSProperties } from 'react';

import classNames from 'classnames/bind';
import style from './Image.module.scss';
const cx = classNames.bind(style);

export type ImageProps = {
    alt?: string;
    backgroundColor?: string;
    className?: string;
    disableLazyLoading?: boolean;
    fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
    focus?:
        | 'center'
        | 'top'
        | 'right'
        | 'left'
        | 'bottom'
        | 'top_right'
        | 'top_left'
        | 'bottom_right'
        | 'bottom_left'
        | 'face'
        | 'faces';
    format?: 'jpg' | 'png' | 'webp';
    formatOption?: 'progressive' | 'png8';
    height?: number;
    inline?: boolean;
    onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    quality?: number;
    radius?: number | 'max';
    round?: boolean;
    size?: {
        height: number;
        width: number;
    };
    src: string;
    width?: number;
};

export const Image: React.FC<ImageProps> = (props) => {
    const [imageRef, imageInView] = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
    });

    const getImageUrl = (): string => {
        const { backgroundColor, fit, focus, format, formatOption, height, quality, radius, src, width } = props;

        const url = new URL(src.replace(/^\/\//, 'https://'));

        /* eslint-disable curly */
        if (format) url.searchParams.set('fm', format);
        if (formatOption) url.searchParams.set('fl', formatOption);
        if (quality) url.searchParams.set('q', quality.toString());
        if (width) url.searchParams.set('w', width.toString());
        if (height) url.searchParams.set('h', height.toString());
        if (focus) url.searchParams.set('f', focus);
        if (fit) url.searchParams.set('fit', fit);
        if (radius) url.searchParams.set('r', radius.toString());
        if (backgroundColor) url.searchParams.set('bg', `rgb:${backgroundColor}`);
        /* eslint-enable curly */

        return url.href;
    };

    const { alt, inline, onLoad, round, disableLazyLoading, size, className } = props;

    const imageStyle: CSSProperties | undefined = size
        ? {
              paddingBottom: `${(size.height / size.width) * 100}%`,
          }
        : undefined;

    return (
        <div
            ref={imageRef}
            className={cx('image', { 'is-inline': inline, 'has-placeholder': imageStyle !== undefined }, className)}
            style={imageStyle}
        >
            {(disableLazyLoading || imageInView) && (
                <img className={cx('image-img', { 'is-round': round })} src={getImageUrl()} alt={alt} onLoad={onLoad} />
            )}
        </div>
    );
};

export default Image;
