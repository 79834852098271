import { BLOCKS, Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';
import React from 'react';

// import { applyShortcode } from '../utility/shortcodes';
// import { table } from '../config/shortcodes';
import Image from './Image';
import Video from './Video';

import classNames from 'classnames/bind';
import style from './RichText.module.scss';
const cx = classNames.bind(style);

export type RichTextProps = {
    className?: string;
    document: Document;
};

export const RichText: React.FC<RichTextProps> = (props) => {
    const { document, className } = props;

    return (
        <div className={cx('rich-text', className)}>
            {documentToReactComponents(document, {
                renderNode: {
                    [BLOCKS.EMBEDDED_ASSET]: (node) => {
                        // Videos
                        if (node.data.target.fields.file.contentType === 'video/mp4') {
                            return <Video src={node.data.target.fields.file.url} controls />;
                        }
                        // Images
                        return (
                            <Image
                                className={cx('image')}
                                src={node.data.target.fields.file.url}
                                alt={node.data.target.fields.title}
                                size={node.data.target.fields.file.details.image}
                            />
                        );
                    },
                    ['asset-hyperlink']: (node) => {
                        const linkText = (node.content[0] as any).value;
                        return <a href={node.data.target.fields.file.url}>{linkText}</a>;
                    },
                    ['entry-hyperlink']: (node) => {
                        const linkText = (node.content[0] as any).value;
                        const linkedContentTypeId = node.data.target.sys.contentType.sys.id;
                        const slug = node.data.target.fields.slug;
                        const to = linkedContentTypeId === 'post' ? `/blog/${slug}` : `/${slug}`;
                        return <Link to={to}>{linkText}</Link>;
                    },
                },
                // renderText: text => {
                //   return applyShortcode(table, text);
                // },
            })}
        </div>
    );
};

export default RichText;
